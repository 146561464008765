import React, {Component} from 'react';

import Block from '../../../../components/block';
import {Colors} from '../../../../constans/colors';

import MiniForm from '../../../../components/mini_form/MiniForm';
import IconsBlock from '../../../../components/icons-block';
import {IconsMode} from '../../../../components/icons-block/types';
import {BlockSubtitle, BlockTitle} from '../../style';
import {ICONS, SLIDER_WORKER, TEXT} from './constants';

import {Container, LeftContent, RightContent} from './style';
import MySlider from '../../../../components/my-slyder/MySlider';

export default class WhoIsBlock extends Component {
  private downloadFile = () => {
    const file = document.getElementById('tttt');
    if (file) {
      file.click();
    }
  }

  public render() {
    return (
      <Block type={Colors.Light}>
        <span id="forWho" />
        <Container>
          <LeftContent>
            <BlockTitle>{TEXT.TITLE}</BlockTitle>
            <BlockSubtitle>{TEXT.SUBTITLE}</BlockSubtitle>
            <IconsBlock iconList={ICONS} mode={IconsMode.Triple} />
          </LeftContent>
          <RightContent>
            <MySlider arr={SLIDER_WORKER} effect='fadeIn' />
          </RightContent>
        </Container>
        <MiniForm btnText={TEXT.FORM_BTN} formText={TEXT.FORM} onSend={this.downloadFile} />
        {/* eslint-disable-next-line */}
        <a href="Catalog.pdf" download="Catalog.pdf" id="tttt" />
      </Block>
    );
  }
}
