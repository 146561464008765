import React, {Component} from 'react';
import {withCookies} from 'react-cookie';
import Block from '../../../../components/block';
import ButtonComponent from '../../../../components/button/ButtonComponent';
import ModalSend from '../../../../components/modal/modal-send/ModalSend';
import {Colors} from '../../../../constans/colors';
import Arrow from '../../../../img/question-form-block/Arrow.svg';
import Note from '../../../../img/question-form-block/note.jpg';
import {TEXT} from './constant';
import {TEXT as FORM_TEXT} from '../../../../components/mini_form/constant';
import {
  ArrowImg,
  ArrowLink,
  ErrorText,
  FormContainer,
  InputBox,
  QuestionBox,
  QuestionForm,
  QuestionFormInput,
  QuestionImg,
  QuestionSubtitle,
  QuestionTextarea,
  QuestionTitle,
} from './style';
import {IState, OwnProps} from './types';
import {bitrixLeadAPI} from '../../../../../../service/api/bitrixApi';
import {sendConversion} from '../../../../../../service/metrika/metrika';
import {AppHistory} from '../../../../../../service/history';
import {telegramAPI} from '../../../../../../service/api/telegramApi';
import {calltouchApi} from '../../../../../../service/api/calltouchApi';

class QuestionFormBlock extends Component<OwnProps, IState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      form: {
        question: '',
        name: '',
        phone: '',
        email: '',
      },
      show: false,
      error: '',
      isInvalid: null,
    };
  }

  public handleSendQuestion = () => {
    const {name, phone, email, question} = this.state.form;
    const sessionId = this.props.cookies?.get('_ct_session_id');
    if (this.state.isInvalid) {
      switch (this.state.isInvalid) {
        case 'phone':
          this.setState({error: FORM_TEXT.INVALID});
          break;
        case 'email':
          this.setState({error: FORM_TEXT.INVALID_EMAIL});
          break;
        default:
          this.setState({error: ''});
          break;
      }
    } else if (name && phone && question) {
      telegramAPI.send({phone: encodeURIComponent(phone), name, email, comments: encodeURIComponent(question)});
      const currentData = {
        phone,
        name,
        email,
        comments: question
      };
      calltouchApi.send({
          subject: 'Блок вопросов',
          requestNumber: String(new Date().getTime()),
          phoneNumber: encodeURIComponent(phone),
          fio: name,
          email,
          comment: encodeURIComponent(question),
          requestUrl: window.location.href,
          sessionId,
      })
      bitrixLeadAPI.create(currentData).then(() => {
        sendConversion();
        AppHistory.push('/ok');
      });
    }
  }


  public handleChangeShow = () => {
    this.setState(prevState => {
      const oldShow = prevState.show;
      return {
        show: !oldShow,
      };
    });
  }

  public handleChangeTextarea = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget;
    const {value} = target;
    const {form} = this.state;

    form.question = value;

    this.setState({form});
  }

  public handleChangeInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const {name, value, validity} = target;
    const {form} = this.state;
    if (name === 'name') {
      form.name = value;
    } else if (name === 'phone') {
      form.phone = value;
      if (validity.valid) {
        this.setState({isInvalid: null});
    } else {
        this.setState({isInvalid: 'phone'});
    }
    } else if (name === 'email') {
      form.email = value;
      if (validity.valid) {
        this.setState({isInvalid: null});
    } else {
        this.setState({isInvalid: 'email'});
    }
    }
    this.setState({form});
  }

  public render() {
    return (
      <Block type={Colors.Light}>
        <span id="question" />
        <QuestionBox>
          <QuestionImg src={Note} alt="note" />
          <QuestionForm>
            <QuestionTitle>{TEXT.TITLE}</QuestionTitle>
            {!this.state.error ? <QuestionSubtitle>{TEXT.SUBTITLE}</QuestionSubtitle> : <ErrorText>{this.state.error}</ErrorText>}
            <FormContainer>
              <InputBox>
                <QuestionFormInput 
                  type="text" 
                  name="name" 
                  placeholder="Имя" 
                  onChange={this.handleChangeInput}
                />
              </InputBox>
              <InputBox>
                <QuestionFormInput 
                  type="tel" 
                  name="phone" 
                  placeholder="Номер" 
                  onChange={this.handleChangeInput}
                  required
                  pattern=".\d{2,12}"
                />
              </InputBox>
              <InputBox>
                <QuestionFormInput 
                  type="email" 
                  name="email" 
                  placeholder="Email" 
                  onChange={this.handleChangeInput}
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                />
              </InputBox>
            </FormContainer>
            <QuestionTextarea onChange={this.handleChangeTextarea} placeholder="Задайте свой вопрос здесь" />
            <ButtonComponent styleType="blue" text={TEXT.BTN_TEXT} action={this.handleSendQuestion} />
          </QuestionForm>
        </QuestionBox>
        <ArrowLink href="#top">
          <ArrowImg src={Arrow} alt="go-top" />
        </ArrowLink>
        <ModalSend
          header="Ваш вопрос отправлен"
          btnTitle="Закрыть"
          action={this.handleChangeShow}
          show={this.state.show}
          personName={this.state.form.name}
        />
      </Block>
    );
  }
}

export default withCookies(QuestionFormBlock);
