import React from 'react';

import {OwnProps, State} from './type';
import {LINK_TEXT, MAIN_TEXT} from './constant';
import TopPanel from '../../components/top-panel';
import FooterBlock from '../main-page/component/footer-block';

import {HomeLink, OkBody, OkContent, OkInfo} from './style';


export default class OkPage extends React.Component<OwnProps, State>{
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      linkText: LINK_TEXT.start
    }
  }

  public componentDidMount(): void {
    setTimeout(this.showHomeBtn, 5000);
  }

  private showHomeBtn = () => {
    return this.setState({linkText: LINK_TEXT.end});
  }

  public render () {
    const {linkText} = this.state;
    return (
      <React.Fragment>
        <TopPanel/>
        <OkBody>
          <OkContent>
            {MAIN_TEXT.thanks}
          </OkContent>
          <HomeLink to="/">
            <OkInfo>
              {linkText}
            </OkInfo>
          </HomeLink>
        </OkBody>
        <FooterBlock />
      </React.Fragment>
    )
  }
}