interface RequestData {
  [variable: string]: any;
}

class Http {
  private headers: Headers = new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  });

  private request<T>(url: string, options: RequestInit): Promise<T> {
    return new Promise<T>((resolve: Function, reject: Function) => {
      fetch(url, options)
        .then((response: Response) => {
          this.checkStatusAndJson<T>(response).then((answer: T) => {
            resolve(answer);
          });
        })
        .catch((error: Error) => {
          reject(error.message);
        });
    });
  }

  protected checkStatusAndJson<T>(response: Response): Promise<T> {
    return response.json();
  }

  public get<T>(url: string) {
    const options: RequestInit = {
      method: 'GET',
      headers: this.headers,
    };

    return this.request<T>(url, options);
  }

  public post<T>(url: string, data: RequestData, option?: {[key: string]: string}) {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        ...this.headers,
        ...option,
      },
      body: JSON.stringify(data),
    };
    return this.request<T>(url, options);
  }
}

export const http = new Http();
