import React, {Component} from 'react';
import {withCookies} from 'react-cookie';

import {bitrixLeadAPI} from '../../../../service/api/bitrixApi';
import {AppHistory} from '../../../../service/history';
import {sendConversion} from '../../../../service/metrika/metrika';
import ButtonComponent from '../button/ButtonComponent';
import TextWithSelection from '../text-with-selection';
import {TEXT} from './constant';
import {IMiniFormInputs, IMiniFormText} from './type';

import {Footnote, InputBox, MainFormText, MiniFormContainer, MiniFormInput, MiniFormRow} from './style';
import {telegramAPI} from '../../../../service/api/telegramApi';
import {calltouchApi} from '../../../../service/api/calltouchApi';

interface IState {
    form: IMiniFormInputs;
    footnote: string;
    phoneValid: boolean;
    emailValid: boolean;
}

class MiniForm extends Component<IMiniFormText, IState> {
    constructor(props: IMiniFormText) {
        super(props);
        this.state = {
            form: {
                name: '',
                phone: '',
                email: '',
            },
            footnote: TEXT.EMPTY,
            phoneValid: false,
            emailValid: false,
        };
    }

    public handleChangeInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        const {name, value, validity} = target;
        const {form} = this.state;

        if (name === 'name') {
            form.name = value;
        } else if (name === 'phone') {
            form.phone = value;
            if (validity.valid) {
                this.setState({phoneValid: true});
            } else {
                this.setState({phoneValid: false});
            }
        } else if (name === 'email') {
            form.email = value;
            if (validity.valid) {
                this.setState({emailValid: true});
            } else {
                this.setState({emailValid: false});
            }
        }
        this.setState({form});
    }

    public handleSendValue = () => {
        const {name, phone, email} = this.state.form;
        const sessionId = this.props.cookies?.get('_ct_session_id');
        if (!this.state.phoneValid) {
            this.setState({footnote: TEXT.INVALID});
        } else if (!this.state.emailValid) {
            this.setState({footnote: TEXT.INVALID_EMAIL});
        } else {
            telegramAPI.send({phone: encodeURIComponent(phone), name, email, comments: this.props.comments});
            calltouchApi.send({
                subject: [this.props.formText.label, this.props.formText.active].join(' '),
                requestNumber: String(new Date().getTime()),
                phoneNumber: encodeURIComponent(phone),
                fio: name,
                email,
                comment: this.props.comments,
                requestUrl: window.location.href,
                sessionId,
            });
            bitrixLeadAPI.create({phone, name, email, comments: this.props.comments}).then(() => {
                sendConversion();
                AppHistory.push('/ok');

                if (this.props.onSend) {
                    this.props.onSend();
                }
            });
        }
    }

    public render() {
        const {formText, btnText} = this.props;
        const {footnote} = this.state;

        return (
            <MiniFormContainer>
                <MiniFormRow>
                    <MainFormText>
                        <TextWithSelection text={formText} />
                    </MainFormText>
                </MiniFormRow>
                <MiniFormRow isInput>
                    <InputBox>
                        <MiniFormInput type="text" name="name" placeholder="Имя" onChange={this.handleChangeInput} />
                    </InputBox>
                    <InputBox>
                        <MiniFormInput
                            type="tel"
                            name="phone"
                            placeholder="Номер *"
                            onChange={this.handleChangeInput}
                            required
                            pattern=".\d{2,12}"
                        />
                        <Footnote error={footnote !== TEXT.EMPTY}>{footnote}</Footnote>
                    </InputBox>
                    <InputBox>
                        <MiniFormInput 
                            type="mail" 
                            name="email" 
                            placeholder="Email" 
                            onChange={this.handleChangeInput}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        />
                    </InputBox>
                    <ButtonComponent styleType="light" text={btnText} action={this.handleSendValue} />
                </MiniFormRow>
            </MiniFormContainer>
        );
    }
}

export default withCookies(MiniForm);
