export interface MailPhone {
    phone: {label: string; active: string};
    email: string;
}
  
interface ContactsType  {
    [key: string]: MailPhone;
}

type Variant = 'MAIN' | 'MANAGER';

class Contacts {

   private variant: Variant;

   private contacts: ContactsType;

   constructor() {
    this.variant = 'MAIN';
    this.contacts = {
        'MAIN': {
            phone: {label: '+7(910)500-19-73', active: '+7(910)'},
            email: 'op@ludi-sila.ru'
        },
        'MANAGER': {
            phone: {label: '+7(910)500-19-73', active: '+7(910)'},
            email: 'op@ludi-sila.ru'
        }
    }
   } 

   getContact = () => {
    return this.contacts[this.variant]
   }

   changeVariant = (variant: Variant) => {
       this.variant = variant;
   }

}

const contacts = new Contacts();
export default contacts;
