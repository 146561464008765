import React, {Component} from 'react';
import {ModalBackground, ModalBody, ModalContainer, ModalTitle} from './style';
import {OwnProps} from './types';

export default class ModalWindow extends Component<OwnProps> {
  public isModal = (target: HTMLElement, className: string): boolean => {
    if (target.id === className) {
      return true;
    }
    if (target.parentElement === null) {
      return false;
    }
    return this.isModal(target.parentElement, className);
  }

  public handleCloseModal = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    if (this.isModal(e.currentTarget, 'modal')) {
      this.props.action();
    }
  }

  public handleStop = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  public render() {
    const {show, header, children, isTransparent = false} = this.props;
    return (
      <ModalBackground show={show} onClick={this.handleCloseModal} id="modal">
        <ModalContainer id="modal-container" onClick={this.handleStop} isTransparent={isTransparent}>
          <ModalTitle>{header}</ModalTitle>
          <ModalBody>{children}</ModalBody>
        </ModalContainer>
      </ModalBackground>
    );
  }
}
