import React, {Component} from 'react';
import {parse} from 'querystring';
import {OwnProps, StateInfo} from './types';

import TopPanel from '../../components/top-panel';
import AboutUsBlock from './component/about-us-block';
import AdvantagesBlock from './component/advantages-block/AdvantagesBlock';
import DifferentTaskBlock from './component/different-tasks-block/DifferentTaskBlock';
import FooterBlock from './component/footer-block';
import HeaderBlock from './component/header-block';
import PowerControlBlock from './component/power-control-block';
import QuestionFormBlock from './component/question-form-block';
import WhoIsBlock from './component/who-is-block';
import FirstScreen from '../../components/can-kviz/FirstScreen';
import CaseBlock from './component/case-block/CaseBlock';
import {bitrixLeadAPI} from '../../../../service/api/bitrixApi';
import contacts from '../../../../service/api/contacts';

class MainPage extends Component<OwnProps, StateInfo> {
  constructor(props: OwnProps) {
    super(props); 
    this.state = {
      search: parse(this.props.location.search.slice(1))
    }
  }
  

  public render() {
    if (this.state.search.manager) {
      bitrixLeadAPI.changeServerUser('NEW_REAL');
      contacts.changeVariant('MANAGER');
    }
    return (
      <React.Fragment>
        <TopPanel />
        <HeaderBlock />
        <AdvantagesBlock />
        <CaseBlock />
        <FirstScreen />
        <DifferentTaskBlock />
        <WhoIsBlock />
        <PowerControlBlock />
        <AboutUsBlock />
        <QuestionFormBlock />
        <FooterBlock />
      </React.Fragment>
    );
  }
}

export default MainPage;
