import styled from 'styled-components';
import {COLORS} from '../../../constans/colors';

interface VisibleStyle {
  show: boolean;
}

export const ModalBackground = styled.div<VisibleStyle>`
  position: fixed;
  width: 100%;
  height: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
`;

interface ModalContainerProps {
  isTransparent?: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 400px;
  background-color: ${props => (props.isTransparent ? 'transparent' : COLORS.BG_LIGHT)};
  color: ${COLORS.FONT_PRIMARY};
  padding: 20px;
  border-radius: 14px;

  @media (max-width: 805px) {
    width: 90%;
  }
`;

export const ModalTitle = styled.h2`
  font-size: xx-large;
  text-align: center;
  color: ${COLORS.FONT_ACTIVE};
`;

export const ModalBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
