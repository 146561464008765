import styled from 'styled-components';
import {COLORS} from '../../constans/colors';
import {BtnType} from './types';

interface IBtnStyle {
    styleType: BtnType;
}

const getBackgroundColor = (props: IBtnStyle): string => {
    switch (props.styleType) {
        case 'green':
            return COLORS.BG_GREEN;
        case 'blue':
        case 'blue-large':
            return COLORS.BG_BLUE;
        case 'light':
            return COLORS.BG_LIGHT;
        default:
            return 'none';
    }
};

export const BtnMain = styled.button<IBtnStyle>`
  width: 340px;
  height: 60px;
  background-color: ${getBackgroundColor};
  color: ${props => (props.styleType === 'light' ? COLORS.FONT_BLUE : COLORS.FONT_LIGHT)};
  font-size: ${props => (props.styleType === 'light' || props.styleType === 'blue-large' ? 'larger' : 'large')};
  border-radius: 5px;
  margin: 10px 10px;

  @media (max-width: 500px) {
    max-width: 280px;
    margin: 10px 0;
  }
`;
