import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router';

import MainPage from '../../packages/site/pages/main-page';
import NotFound from '../../packages/site/pages/not-found';
import OkPage from '../../packages/site/pages/ok-page/OkPage';

export default class Router extends Component {
  public render() {
    return (
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route path="/404" status={404} component={NotFound} />
        <Route path="/ok" component={OkPage} />
        <Redirect path="*" to="/404" />
      </Switch>
    );
  }
}
