import Time24 from '../../../../img/advantages-block/icon.svg';
import Money from '../../../../img/advantages-block/icon1.svg';
import Arms from '../../../../img/advantages-block/icon4.svg';
import Man from '../../../../img/advantages-block/icon5.svg';
import Town from '../../../../img/advantages-block/icon8.svg';


export const ADVANTAGES_TITLE = {
  label: 'Чем мы полезны для бизнеса',
  active: 'полезны для бизнеса'
}

export const ADVANTAGES = [
  {
    text: "В вашем распоряжении будет 10 человек в течении 24 часов. Если нужно больше, дайте нам день, и мы удвоим колличество.",
    icon: Time24
  },
  {
    text: "Вы можете оплатить за рабочих на р/с, по договору подряда. Просто, быстро, удобно.",
    icon: Money
  },
  {
    text: "Если в течении 2 часов рабочего дня кто-то из рабочих покажется вам некомпетентным, вы сможете отправить его домой без оплаты, оповестив нас об этом.",
    icon: Arms
  },
  {
    text: "За вами будет закреплен человек, который решает любые вопросы с подбором персонала. У нас работают профессиональные менеджеры по подбору персонала и есть своя IT система.",
    icon: Man
  },
  {
    text: "Мы обеспечим персоналом в любом крупном городе в 3 смены и 7 дней в неделю.",
    icon: Town
  }
];