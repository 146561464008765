export enum Colors {
  Light = '#ffffff',
  Dark = '#0E1A2F',
  Active = '#61D46F',
  DarkBlue = '#0E357B',
  Blue = '#6D83A7',
  LightBlue = '#E8ECF2',
  Grey = '#F5F7FB',
  MiddleGrey = '#E8ECF2',
  DarkGrey = '#94969D',
  Red = '#dc0101',
}

export const COLORS = {
  BG_LIGHT: Colors.Light,
  BG_GREY: Colors.Grey,
  BG_DARK: Colors.Dark,
  BG_BLUE: Colors.DarkBlue,
  BG_GREEN: Colors.Active,
  BG_SECONDARY: Colors.MiddleGrey,
  BG_ACTIVE: Colors.Active,

  FONT_PRIMARY: Colors.Dark,
  FONT_ACTIVE: Colors.Active,
  FONT_SECONDARY: Colors.DarkGrey,
  FONT_LIGHT: Colors.Light,
  FONT_MIDDLE: Colors.MiddleGrey,
  FONT_INPUT_PRIMARY: Colors.LightBlue,
  FONT_INPUT_SECONDARY: Colors.Blue,
  FONT_BLUE: Colors.DarkBlue,

  ICON: Colors.DarkBlue,

  BORDER_LITE: Colors.Light,
  BORDER_PRIMARY: Colors.Dark,
  BORDER_RED: Colors.Red,
  BORDER_BLUE: Colors.Blue,
  BORDER_GREY: Colors.MiddleGrey,
};
