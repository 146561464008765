import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo} from 'react';

import {cn} from '../../../../utils/classNames';
import './CheckboxButton.scss';
import {
    faCheckCircle,
    faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

type OwnProps = React.PropsWithChildren<{
    checked: boolean;
    name: string;
    onChange: Function;
    className?: string;
    type?: string;
}>;

const b = cn('CheckboxButton');

const CheckboxButton: React.FC<OwnProps> = ({checked, name, onChange, className = '', children, type= 'checkbox'}) => {
    const id = useMemo(() => `CheckboxButton${name}`, [name]);

    let checkboxIcon ;

    if (type === 'radio'){
        checkboxIcon = checked ? faCheckCircle : faCircleNotch;
    } else {
        checkboxIcon = checked ? faCheckSquare : faSquare;
    }

    return (
        <React.Fragment>
            <input className={b('invisible')} type={type} id={id} checked={checked} onChange={(event) => onChange(event)} />
            <label className={b('content', {checked}, [className])} htmlFor={id} >
                <FontAwesomeIcon className={b('checkbox', {checked})} icon={checkboxIcon} />
                <div className={b('children')}>{children}</div>
            </label>
        </React.Fragment>
    );
};

export default CheckboxButton;
