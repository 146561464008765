import React from 'react';
import Slider from 'react-slick';

import Block from '../../../../components/block/Block';
import {Colors} from '../../../../constans/colors';
import {CASE} from './constants';

import {
  CaseContainer,
  CaseImg, CaseInfo,
  CaseItem,
  CaseName,
  CasePeople,
  CaseService,
} from './style';


interface OwnProps {}

interface CaseInterface {
  name: string;
  service: string;
  people: string;
  image: string;
}

const settings = {
  dots: true,
  infinite: true,
  accessibility: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        arrows: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
}

const CaseBlock: React.FC<OwnProps> = () => {
  return (
    <Block type={Colors.Light}>
      <CaseContainer>
        <Slider {...settings}>
          {CASE.map((item: CaseInterface, index: number) => {
            return (
              <div key={index}>
              <CaseItem>
                <CaseImg src={item.image} alt={item.name}/>
                <CaseInfo>
                  <CaseName>
                    {item.name}
                  </CaseName>
                  <CaseService>
                    {item.service}
                  </CaseService>
                  <CasePeople>
                    {item.people}
                  </CasePeople>
                </CaseInfo>
              </CaseItem>
              </div>
            )
          })}
        </Slider>
      </CaseContainer>
      <span id="cankviz" />
    </Block>
  )
}
export default React.memo(CaseBlock);
