import React, {Component} from 'react';
import {CardImg, CardText, CardTitle, DescriptionCard} from './style';
import {OwnProps} from './types';

export default class Card extends Component<OwnProps> {
  public render() {
    const {title, text, img} = this.props;
    return (
      <DescriptionCard>
        <CardImg src={img} alt={title} />
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </DescriptionCard>
    );
  }
}
