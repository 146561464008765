import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 1200px) {
    width: 65%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const AboutImage = styled(Col)`
  width: 50%;
  @media (max-width: 1200px) {
    width: 35%;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

export const Image = styled.img`
  border-radius: 14px;
  width: 100%;
  height: 25vw;
  @media (max-width: 1200px) {
    height: 20vw;
  }
`;
