import {http} from './http';
import {messageForBot} from './mathers';
import {Lead} from './types';

class TelegramAPI {
  private chatId: string; 

  private botToken: string;

  constructor() {
    this.chatId = '-688641331';
    this.botToken = '5281322525:AAHUvHbN2kM5YGQgMnB35Xgvfe1mdOAbSoU'
  }

  public send = (options: Lead) => {
  
    const url = `https://api.telegram.org/bot${this.botToken}/sendMessage?chat_id=${this.chatId}&parse_mode=html&text=${messageForBot(options)}`;

    return http.get(url);
  }
}

export const telegramAPI = new TelegramAPI();
