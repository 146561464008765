import React, {Component} from 'react';

import {BtnMain} from './style';
import {OwnProps} from './types';

export default class ButtonComponent extends Component<OwnProps> {
  public handleStartAction = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.props.action();
  }

  public render() {
    const {styleType, text} = this.props;
    return (
      <BtnMain type="button" styleType={styleType} onClick={this.handleStartAction}>
        {text}
      </BtnMain>
    );
  }
}
