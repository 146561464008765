import React, {Component} from 'react';

import Block from '../../../../components/block';
import {Colors} from '../../../../constans/colors';
import {BlockTitle} from '../../style';

import Carousel from '../../../../components/carousel';
import MiniForm from '../../../../components/mini_form/MiniForm';
import {CAROUSEL_LIST, TEXT} from './constants';

export default class DifferentTaskBlock extends Component {
  public render() {
    return (
      <Block type={Colors.Grey}>
        <span id="different" />
        <BlockTitle centerOnMini>{TEXT.TITLE}</BlockTitle>
        <Carousel itemList={CAROUSEL_LIST} />
        <MiniForm btnText={TEXT.FORM_BTN} formText={TEXT.FORM} />
      </Block>
    );
  }
}
