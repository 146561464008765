import styled from 'styled-components';

export const Row = styled.div`
  min-height: 155px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  @media (max-width: 580px) {
    padding: 20px 0;
  }
`;

export const FooterLogoCol = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  z-index: 200;
  @media (max-width: 820px) {
    margin-left: 10px;
  }
  @media (max-width: 580px) {
    justify-content: center;
    margin: 10px auto;
  }
`;

export const HalfRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;
  @media (max-width: 580px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const TextRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 580px) {
    text-align: center;
  }
`;

export const TextRow = styled.div`
  @media (max-width: 805px) {
    width: 300px;
  }
`;

export const HideText = styled.div`
@media (max-width: 580px) {
  display: none;
}
`;

export const LogoImage = styled.img`
  width: 174px;
`;
