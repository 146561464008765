import BuildingOrganizationIcon from '../../../../img/who-is-block/building_organization.svg';
import ForemenIcon from '../../../../img/who-is-block/foremen.svg';
import PowerObjectIcon from '../../../../img/who-is-block/power_object.svg';
import ProductionIcon from '../../../../img/who-is-block/production.svg';
import TransportCompanyIcon from '../../../../img/who-is-block/transport_company.svg';
import WarehouseIcon from '../../../../img/who-is-block/warehouse.svg';

import Worker1 from '../../../../img/who-is-block/slider/worker1.png';
import Worker2 from '../../../../img/who-is-block/slider/worker2.png';
import Worker3 from '../../../../img/who-is-block/slider/worker3.png';
import Worker4 from '../../../../img/who-is-block/slider/worker4.png';
import Worker5 from '../../../../img/who-is-block/slider/worker5.png';

export const YOUTUBE_ID = 'https://www.youtube.com/embed/sddJjfVKnjA';
export const LOCAL_VIDEO = 'http://ludi-sila.ru/sila.mp4';

export const TEXT = {
  TITLE: 'Для кого',
  SUBTITLE:
    '«Сильные люди» - не только компания, зарекомендовавшая себя на рынке, но и ресурс. С помощью него вы ускоряете производство работ, снимаете нагрузку, связанную с подбором персонала, а так же экономите своё время и деньги.',
  FORM: {
    label: 'Оставьте заявку что бы начать работы точно в срок',
    active: 'точно в срок',
  },
  FORM_BTN: 'Подобрать персонал',
};

export const ICONS = [
  {label: 'Складов', icon: WarehouseIcon},
  {label: 'Строительных организаций', icon: BuildingOrganizationIcon},
  {label: 'Производств', icon: ProductionIcon},
  {label: 'Транспортных компаний', icon: TransportCompanyIcon},
  {label: 'Объектам где требуются рабочие', icon: PowerObjectIcon},
  {label: 'Прорабов', icon: ForemenIcon},
];

export const SLIDER_WORKER = [
  {
    img: Worker1,
    name: 'Рабочий'
  },
  {
    img: Worker2,
    name: 'Рабочий'
  },
  {
    img: Worker3,
    name: 'Рабочий'
  },
  {
    img: Worker4,
    name: 'Рабочий'
  },
  {
    img: Worker5,
    name: 'Рабочий'
  },
]