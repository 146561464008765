import styled from 'styled-components';
import {COLORS} from '../../constans/colors';

export const DescriptionCard = styled.div`
  width: 400px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  background-color: ${COLORS.BG_LIGHT};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  padding-bottom: 20px;
  
  @media only screen and (min-width: 1700px) {
    width: 450px;
  }

  @media (max-width: 1550px) {
    width: 320px;
  }

  @media (max-width: 1250px) {
    width: 100%;
    height: auto;
    box-shadow: none;
    border-radius: 0;
    background: none;
    justify-content: center;
    align-items: center;
  }
`;

export const CardImg = styled.img`
  height: 240px;
  margin-top: 40px;
  align-self: center;
  @media (max-width: 1250px) {
    margin-top: 10px;
  }
`;

export const CardTitle = styled.h3`
  margin: 40px;
  text-align: center;
  @media (max-width: 1250px) {
    margin: 20px;
  }
`;

export const CardText = styled.p`
  margin: 0 40px;
  line-height: 24px;
  @media (max-width: 1250px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
