import React, {Component} from 'react';

import {cn} from '../../../../utils/classNames';
import {OwnProps} from './types';

import './TextWithSelection.scss';

const b = cn('TextWithSelection');

export default class TextWithSelection extends Component<OwnProps, {}> {
  public renderText() {
    const {label, active} = this.props.text;
    const textList = label.split(active);
    return textList.map((text: string, index: number) => {
      if (index === 0) {
        return text;
      }

      return (
        <React.Fragment key={index}>
          <span className={b('activeSpan')}>{active}</span>
          {text}
        </React.Fragment>
      );
    });
  }

  public render() {
    return <React.Fragment>{this.renderText()}</React.Fragment>;
  }
}
