import {BitrixDataTypes} from './enums';
import {BitrixData, Lead} from './types';

export const prepareLead = ({
    phone = '',
    name = 'Без имени',
    email = '',
    title = 'Заявка с сайта от',
    comments = '',
}: Lead): BitrixData => ({
    fields: {
        TITLE: `${title} ${name}`,
        NAME: name,
        PHONE: [
            {
                VALUE: phone,
                VALUE_TYPE: BitrixDataTypes.Work,
            },
        ],
        EMAIL: [
            {
                VALUE: email,
                VALUE_TYPE: BitrixDataTypes.Work,
            },
        ],
        ASSIGNED_BY_ID: 47,
        COMMENTS: comments,
    },
});

export const messageForBot = ({phone = '', email = '', name = '', comments = ''}: Lead) => {
  return `
<b>Новая заявка с сайта "Сильные люди":</b>%0A
<u>Имя:</u> ${name},%0A
<u>Телефон:</u> ${phone} ,%0A
<u>Эл.почта:</u> ${email},%0A
<u>Комментарий:</u> ${comments}
  `
};
