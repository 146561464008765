import styled, {css} from 'styled-components';
import {COLORS} from '../../constans/colors';

export const ActiveSpan = styled.span`
  color: ${COLORS.FONT_ACTIVE};
`;

interface BlockTitleProps {
  centerOnMini?: boolean;
}
export const BlockTitle = styled.h2<BlockTitleProps>`
  font-size: 36px;
  align-self: flex-start;
  
  color: ${COLORS.FONT_PRIMARY};
  margin-bottom: 15px;
  font-weight: normal;
  padding: 10px;
  @media (max-width: 805px) {
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    text-align: ${props => props.centerOnMini && 'center'};
    font-size: 24px;
  }
`;

export const BlockDesktopTitle = styled.h2`
  font-size: 36px;
  margin-top: 20px;
  color: ${COLORS.FONT_PRIMARY};
  @media (max-width: 805px) {
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

export const BlockMobileTitle = styled.h2`
  font-size: 36px;
  color: ${COLORS.FONT_PRIMARY};
  display: none;
  @media (max-width: 500px) {
    display: inherit;
    margin-top: 30px;
  }
`;

export const clearButton = css`
  border: none;
  background-color: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const BlockSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  margin-bottom: 10px;
`;
