export interface ConstantText {
  label: string;
  active: string;
}

export interface TextWithIconType {
  icon: string;
  text: ConstantText;
  link: string;
}

export interface Constants {
  TITLE: ConstantText;
  SUBTITLE: ConstantText;
}

export const TEXT: Constants = {
  TITLE: {
    label: 'Персонал на строительные\nи производственные площадки.',
    active: 'Персонал',
  },
  SUBTITLE: {
    label: 'Гарантированно предоставим бригаду рабочих \nот 1 до 100 человек строго по вашим критериям.',
    active: '',
  },
};

export const MENU_LIST: Array<TextWithIconType> = [
  {
    icon: '',
    text: {
      label: 'Главная',
      active: '',
    },
    link: '#top',
  },
  {
    icon: '',
    text: {
      label: 'Преимущества',
      active: '',
    },
    link: '#advantages',
  },
  {
    icon: '',
    text: {
      label: 'Разные цели',
      active: '',
    },
    link: '#different',
  },
  {
    icon: '',
    text: {
      label: 'Для кого?',
      active: '',
    },
    link: '#forWho',
  },
  {
    icon: '',
    text: {
      label: 'Сила в контроле',
      active: '',
    },
    link: '#powerControl',
  },
  {
    icon: '',
    text: {
      label: 'О нас',
      active: '',
    },
    link: '#aboutUs',
  },
  {
    icon: '',
    text: {
      label: 'Задать вопрос',
      active: '',
    },
    link: '#question',
  },
  {
    icon: '',
    text: {
      label: 'Блог нашей компании',
      active: '',
    },
    link: 'https://blog.ludi-sila.ru',
  },
];

export const FOOTER = {
  RIGHT: {
    label: 'Условия Политики обработки персональных данных и',
    active: 'Политики обработки персональных данных',
  },
  RIGHT_SECOND: {
    label: 'Политики конфиденциальности',
    active: 'Политики конфиденциальности',
  },
};
