import Agroteh from '../../../../img/case-block/argoteh.png';
import Avangard from '../../../../img/case-block/avangard.png';
import Cherch from '../../../../img/case-block/cherch.png';
import Funky from '../../../../img/case-block/funky.png';
import Globus from '../../../../img/case-block/globus.png';
import Jasper from '../../../../img/case-block/jasper.png';
import Karusel from '../../../../img/case-block/karusel.png';
import Kran from '../../../../img/case-block/kran.png';
import Krasnobakov from '../../../../img/case-block/krasnobakov.png';
import Magnit from '../../../../img/case-block/magnit.png';
import Okskoe from '../../../../img/case-block/okskoe.png';
import Ryazan from '../../../../img/case-block/ryazan.png';
import Sets from '../../../../img/case-block/sets.png';
import Sky from '../../../../img/case-block/sky.png';
import Svyaz from '../../../../img/case-block/svyaz.png';
import Tehuniver from '../../../../img/case-block/tehuniver.png';
import Tochinvest from '../../../../img/case-block/tochinvest.png';
import Vdnh from '../../../../img/case-block/vdnh.jpeg';

export const CASE = [
  {
    name: 'Училище Связи им. Захарова',
    service: 'Монтажные, демонтажные, отделочные, подсобные работы',
    people: '20-35 человек',
    image: Svyaz
  },
  {
    name: '3 церкви и Благовещенский и Печерский монастыри',
    service: 'земляные, монтажные, демонтажные работы',
    people: '12-25 человек',
    image: Cherch
  },
  {
    name: 'Сеть магазинов “Магнит”',
    service: 'Демонтажные, отделочные, погрузо-разгрузочные работы',
    people: '18-26 человек',
    image: Magnit
  },
  {
    name: 'Парк ВДНХ',
    service: 'Монтаж ограждающих конструкций',
    people: '12-15 человек',
    image: Vdnh
  },
  {
    name: 'Агротеххолдинг',
    service: 'Монтажные, подсобные, сварочные работы',
    people: '14-20 человек',
    image: Agroteh
  },
  {
    name: 'СпецЭнергоТрансСервис',
    service: 'Стропальщики',
    people: '10-25 человек',
    image: Sets
  },
  {
    name: 'Краснобаковские молочные продукты',
    service: 'Линейный персонал на производство',
    people: '10-16 человек',
    image: Krasnobakov
  },
  {
    name: 'Окская птицефабрика',
    service: 'Прокладка пожарного трубопровода',
    people: '10-15 человек',
    image: Okskoe
  },
  {
    name: 'Рязанский Станкозавод',
    service: 'Резка и гибка металла, сварочные работы',
    people: '20 человек',
    image: Ryazan
  },
  {
    name: 'ГК Точинвест-Строй',
    service: 'Монтаж подпорных стен',
    people: '13-18 человек',
    image: Tochinvest
  },
  {
    name: 'Ресторан Небо',
    service: 'Реконструкция, демонтажные работы',
    people: '16-20 человек',
    image: Sky
  },
  {
    name: 'Глобус',
    service: 'Строительные земляные работы',
    people: '28-36 человек',
    image: Globus
  },
  {
    name: 'Кран-Эксперт',
    service: 'Реконструкция подъемных механизмов, подсобные работы',
    people: '19 человек',
    image: Kran
  },
  {
    name: 'Карусель',
    service: 'Погрузо-разгрузочные работы',
    people: '12-14 человек',
    image: Karusel
  },
  {
    name: 'Авангард',
    service: 'Погрузо-разгрузочные работы, уборка территории',
    people: '15 человек',
    image: Avangard
  },
  {
    name: 'Университет Технологий Управления',
    service: 'Монтажные, сварочные работы',
    people: '19 человек',
    image: Tehuniver
  },
  {
    name: 'Парк развлечений FunkyTown',
    service: 'Погрузо-разгрузочные работы, монтаж оборудования',
    people: '5-15 человек',
    image: Funky
  },
  {
    name: 'Jasper Wall Management\n',
    service: 'Монтаж системы водоснабжения',
    people: '38-45 человек',
    image: Jasper
  },
]
