import React, {Component} from 'react';
import ButtonComponent from '../../button/ButtonComponent';
import {ModalBody, ModalTitle, PoliticaFrame, PrivacyInfo} from './style';
import {OwnProps} from './type';

export default class ModalPolicy extends Component<OwnProps> {
  public render() {
    const {show, action} = this.props;
    return (
      <ModalBody show={show}>
        <PrivacyInfo>
          <ModalTitle>Ознакомьтесь с условиями</ModalTitle>
          <PoliticaFrame
            src="./politica.html"
            id="myFrame"
            title="Политика конфиденциальности персональных данных"
          />
          <ButtonComponent styleType="blue" text="Принять" action={() => action()} />
        </PrivacyInfo>
      </ModalBody>
    );
  }
}
