import React, {Component} from 'react';

import {InnerContainer, StyledContainer} from './style';
import {OwnProps} from './types';

export default class Block extends Component<OwnProps> {
  public render() {
    const {children, type, image, withoutPadding} = this.props;
    return (

      <StyledContainer
        backgroundColor={type}
        backgroundImage={image || ''}
        withoutPadding={withoutPadding}
 >
        <InnerContainer>{children}</InnerContainer>
      </StyledContainer>
    );
  }
}
