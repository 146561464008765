import React, {Component} from 'react';

import LeftArrowIcon from '../../img/control/left-arrow.svg';
import RightArrowIcon from '../../img/control/right-arrow.svg';
import IconsBlock from '../icons-block';
import {IconsMode} from '../icons-block/types';
import {ArrowButton, Container, ContentCol, ImageCol, MenuButton, MenuItem, Row, TopMenu} from './style';
import {CarouselItem, CarouselState, OwnProps} from './types';

interface OnArrowClickFunction {
  (): void;
}

export default class Carousel extends Component<OwnProps, CarouselState> {
  public constructor(props: OwnProps) {
    super(props);

    this.state = {
      carouselItems: props.itemList,
      selectedItem: props.itemList[0],
      selectedIndex: 0,
    };
  }

  private handleSelect = (index: number) => {
    this.setState(prevState => {
      return {
        selectedItem: prevState.carouselItems[index],
        selectedIndex: index,
      };
    });
  }

  private handleArrowClick = (vector: number) => {
    let index = this.state.selectedIndex + vector;

    if (index < 0) {
      index = this.state.carouselItems.length - 1;
    } else if (index >= this.state.carouselItems.length) {
      index = 0;
    }

    this.handleSelect(index);
  }

  private handleLeftClick = () => this.handleArrowClick(-1);

  private handleRightClick = () => this.handleArrowClick(1);

  private renderArrowButton = (arrowImage: string, onClick: OnArrowClickFunction) => {
    return (
      <ArrowButton onClick={onClick}>
        <img src={arrowImage} alt="arrow" />
      </ArrowButton>
    );
  }

  private renderMenuItems = () => {
    const {carouselItems, selectedItem} = this.state;

    return carouselItems.map((item: CarouselItem, index: number) => {
      const handleMenuButtonClick = () => {
        this.handleSelect(index);
      };

      return (
        <MenuItem key={index}>
          <MenuButton onClick={handleMenuButtonClick} isActive={selectedItem.title === item.title}>
            {item.title}
          </MenuButton>
        </MenuItem>
      );
    });
  }

  private renderTopMenu = () => {
    return (
      <TopMenu>
        {this.renderArrowButton(LeftArrowIcon, this.handleLeftClick)}
        <Row>{this.renderMenuItems()}</Row>
        {this.renderArrowButton(RightArrowIcon, this.handleRightClick)}
      </TopMenu>
    );
  }

  public render = () => {
    const {image, iconList} = this.state.selectedItem;
    return (
      <Container>
        <ContentCol>
          {this.renderTopMenu()}
          <IconsBlock iconList={iconList} mode={IconsMode.Carousel} />
        </ContentCol>
        <ImageCol image={image} />
      </Container>
    );
  }
}
