import styled, {css} from 'styled-components';
import {COLORS} from '../../constans/colors';
import Vector from '../../img/Vector.png';

const placeHolderColor = css`
  color: ${COLORS.FONT_MIDDLE};
  opacity: 1;
`;

const placeHolder = css`
  ::-webkit-input-placeholder {
    ${placeHolderColor};
  }

  ::-moz-placeholder {
    ${placeHolderColor};
  }

  :-moz-placeholder {
    ${placeHolderColor};
  }

  :-ms-input-placeholder {
    ${placeHolderColor};
  }
`;

export const MiniFormInput = styled.input`
  border: none;
  border-bottom: 1px solid ${COLORS.BORDER_BLUE};
  background: none;
  color: ${COLORS.FONT_LIGHT};
  margin-bottom: 20px;
  ${placeHolder};
  @media (max-width: 805px) {
    width: 100%;
  }
`;

export const MiniFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.BG_BLUE};
  background-image: url(${Vector});
  color: ${COLORS.FONT_LIGHT};
  padding: 50px 70px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 80px;

  @media (max-width: 805px) {
    padding: 20px 30px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
`;
interface MiniFormProps {
  isInput?: boolean;
}
export const MiniFormRow = styled.div<MiniFormProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 805px) {
    justify-content: center;
    align-self: center;
    max-width: ${props => props.isInput && 380}px;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 805px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

interface FootnoteProps {
  error: boolean;
}

export const Footnote = styled.p<FootnoteProps>`
  font-size: ${props => props.error ? 'large' : 'small'};
  text-align: center;
  color: ${props => props.error ? 'red' : COLORS.FONT_INPUT_SECONDARY};
  @media (max-width: 805px) {
    font-size: x-small;
  }
`;

export const MainFormText = styled.h2`
  margin-bottom: 35px;
  width: 70%;
  font-weight: normal;
  @media (max-width: 805px) {
    width: 100%;
    font-size: x-large;
    text-align: center;
  }
`;
