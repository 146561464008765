import {CarouselItemList} from '../../../../components/carousel/types';

import ConcretesIcon from '../../../../img/different-tasks-block/concretes.svg';
import ConstructionProjectBackground from '../../../../img/different-tasks-block/construction_project.jpg';
import ElectriciansIcon from '../../../../img/different-tasks-block/electricians.svg';
import ForemenIcon from '../../../../img/different-tasks-block/foremen.svg';
import GeneralWorkersIcon from '../../../../img/different-tasks-block/general_workers.svg';
import InstallersIcon from '../../../../img/different-tasks-block/installers.svg';
import PersonnelSelectionIcon from '../../../../img/different-tasks-block/personnel_selection.svg';
import PortersIcon from '../../../../img/different-tasks-block/porter.svg';
import WeldersIcon from '../../../../img/different-tasks-block/welders.svg';

import MoversToTheWarehouseIcon from '../../../../img/different-tasks-block/movers_to_the_warehouse.svg';
import PackersIcon from '../../../../img/different-tasks-block/packers.svg';
import PlotMasterIcon from '../../../../img/different-tasks-block/plot_master.svg';
import ProductionBackground from '../../../../img/different-tasks-block/production.jpg';
import SlingersIcon from '../../../../img/different-tasks-block/slingers.svg';
import SpecializedStaffIcon from '../../../../img/different-tasks-block/specialized_staff.svg';
import UnskilledWorkersInProductionIcon from '../../../../img/different-tasks-block/unskilled_workers_in_production.svg';

export const TEXT = {
  TITLE: 'Разные цели – разные рабочие',
  SUBTITLE:
    '«Сильные люди» - на 100% автоматизированный бизнес, но кроме программ, процессов, сотрудников, сервиса, в нем есть частичка души. Именно поэтому 95% клиентов говорят о том, \nчто с нами приятно работать и обращаются вновь.',
  FORM: {
    label: 'Оставьте заявку и мы подбреем нужный персонал',
    active: 'нужный персонал',
  },
  FORM_BTN: 'Подобрать персонал',
};

export const CAROUSEL_LIST: CarouselItemList = [
  {
    title: 'Строительные объекты',
    image: ConstructionProjectBackground,
    iconList: [
      {label: 'Разнорабочие', icon: GeneralWorkersIcon, subtitle: '~ 1400 ₽/смена'},
      {label: 'Сварщики', icon: WeldersIcon, subtitle: '~ 2000 ₽/смена'},
      {label: 'Грузчики', icon: PortersIcon, subtitle: '~ 1600 ₽/смена'},
      {label: 'Электрики', icon: ElectriciansIcon, subtitle: '~ 2000 ₽/смена'},
      {label: 'Монтажники', icon: InstallersIcon, subtitle: '~ 1800 ₽/смена'},
      {label: 'Прорабы', icon: ForemenIcon, subtitle: '~ 2000 ₽/смена'},
      {label: 'Бетонщики', icon: ConcretesIcon, subtitle: '~ 1800 ₽/смена'},
      {label: 'Подбор персонала по вашим критериям', icon: PersonnelSelectionIcon},
    ],
  },
  {
    title: 'Производство',
    image: ProductionBackground,
    iconList: [
      {label: 'Разнорабочие на производство', icon: UnskilledWorkersInProductionIcon, subtitle: '~ 1400 ₽/смена'},
      {label: 'Стропальщики', icon: SlingersIcon, subtitle: '~ 1600 ₽/смена'},
      {label: 'Специализированный персонал', icon: SpecializedStaffIcon, subtitle: '~ 1600 ₽/смена'},
      {label: 'Мастера участка', icon: PlotMasterIcon, subtitle: '~ 2000 ₽/смена'},
      {label: 'Упаковщики', icon: PackersIcon, subtitle: '~ 1400 ₽/смена'},
      {label: 'Грузчики на склад', icon: MoversToTheWarehouseIcon, subtitle: '~ 1600 ₽/смена'},
    ],
  },
];
