import {Methods} from './enums';
import {http} from './http';
import {prepareLead} from './mathers';
import {Lead, ServerInfo} from './types';

interface Server {
  [key : string]: ServerInfo;
}
type User = 'REAL' | 'NEW_REAL' | 'TEST';

class BitrixLeadAPI {
  private serverUser: User; 

  private server: Server;

  constructor() {
    this.serverUser = 'NEW_REAL';
    this.server = {
      'REAL': {
        ACCOUNT: '1',
        KEY: '4sj4qgxc10bolow4',
        URL: 'https://silavnas.bitrix24.ru/rest',
      },
      'TEST': {
        ACCOUNT: '1',
        KEY: 'pi0r7khotehd12jk',
        URL: 'https://b24-r49xrz.bitrix24.ru/rest',
      },
      'NEW_REAL': {
        ACCOUNT: '1',
        KEY: 'mtqik5id0k3atg5i',
        URL: 'https://crm.ludi-sila.ru/rest',
      },
    };;
  }

  public changeServerUser = (serverUser: User) => {
    this.serverUser = serverUser;
  }

  public create = (options: Lead) => {
    const server = this.server[this.serverUser];
    const API_URL = `${server.URL}/${server.ACCOUNT}/${server.KEY}`;
    const url = `${API_URL}/${Methods.AddLead}`;

    return http.post(url, prepareLead(options));
  }
}

export const bitrixLeadAPI = new BitrixLeadAPI();
