import styled from 'styled-components';

interface StyleProps {
  show: boolean;
  effect: string;
}

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderImg = styled.img<StyleProps>`
  max-width: 500px;
  border-radius: 10px;
  display: ${props => props.show? 'block': 'none'};
  animation: ${props => props.effect? props.effect : 'none'} 3s;
  @media (max-width: 780px) {
    width: 100%;
  }
  
  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
  }
  
  @keyframes leftIn {
    from {left: -100%}
    80% {left: 20%}
    to {left: 0}
  }
  
  @keyframes topIn {
    from {top: -100%}
    80% {top: 20%}
    to {top: 0}
  }
`;
