import styled from 'styled-components';

export const CaseItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 180px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  margin: 30px 20px;
  padding: 10px;
  @media (max-width: 600px) {
    flex-direction: column;
    height: 350px;
  }
`;

export const CaseContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

export const CaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  @media (max-width: 600px) {
   align-items: center;
  }
`;

export const CaseImg = styled.img`
  width: 150px;
  margin: 10px;
  @media (max-width: 1400px) {
   width: 100px;
  }
  @media (max-width: 600px) {
   width: 200px;
  }
`;

export const CaseName = styled.h3`
  font-size: 16px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    text-align: center;  
  }
`;

export const CaseService = styled.div`
  font-size: 14px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const CasePeople = styled.div`
  margin-top: 15px;
  font-size: 20px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
