import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import {render} from 'react-dom';
import {Router as BrowserRouter} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {CookiesProvider} from 'react-cookie';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as serviceWorker from './serviceWorker';

import './clear-style.css';
import './fonts/fonts.css';
import './packages/site/constans/colors.scss';

import {AppHistory} from './service/history';
import Router from './service/router/router';

const metrikaOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: 'dataLayer',
};

const App = () => (
    <React.Fragment>
        <CookiesProvider>
            <BrowserRouter history={AppHistory}>
                <Router />
            </BrowserRouter>
            <YMInitializer accounts={[48404555]} options={metrikaOptions} version="2" />
        </CookiesProvider>
    </React.Fragment>
);

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
