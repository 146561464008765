import React, {Component} from 'react';
import ButtonComponent from '../../button/ButtonComponent';
import ModalWindow from '../modal-window/ModalWindow';
import {ModalInfo} from './style';
import {OwnProps} from './types';

export default class ModalSend extends Component<OwnProps> {
  public render() {
    const {show, btnTitle, header, action, personName} = this.props;
    return (
      <ModalWindow header={header} show={show} action={action}>
        <ModalInfo>
          {personName || 'Уважаемый'}, ваши данные отправились на обработку. Наш менеджер свяжется с вами в ближайшее
          время.
        </ModalInfo>
        <ButtonComponent styleType="green" text={btnTitle} action={action} />
      </ModalWindow>
    );
  }
}
