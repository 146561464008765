import {http} from './http';
import {CalltouchLead} from './types';

export const modId = '00mc3vy6';

class CalltouchApi {
  private siteId: string; 

  private URL: string;

  private endPoint: string;

  constructor() {
    this.siteId = '35248';
    this.URL = 'https://api.calltouch.ru/calls-service/RestAPI/requests';
    this.endPoint = 'register/'
  }

  public send = (options: CalltouchLead) => {
    const apiUrl = [this.URL, this.siteId, this.endPoint].join('/');
    const params = Object.entries(options).reduce((result: string[], [key, value]) => {
      if (value) {
        result.push(`${key}=${value}`);
      }
      return result;
    }, [])

    return http.get(`${apiUrl}?${params.join('&')}`);
  }
}

export const calltouchApi = new CalltouchApi();
