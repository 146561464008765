import React, {Component} from 'react';

import Block from '../../../../components/block';
import IconsBlock from '../../../../components/icons-block';
import {IconsMode} from '../../../../components/icons-block/types';
import MiniForm from '../../../../components/mini_form/MiniForm';
import {Colors} from '../../../../constans/colors';
import AboutUsImage from '../../../../img/about-us-block/about_us_img.jpeg';
import {BlockTitle} from '../../style';
import {ICONS, TEXT} from './constants';
import {AboutImage, Col, Image, Row} from './style';

export default class AboutUsBlock extends Component {
  public render() {
    return (
      <Block type={Colors.Light}>
        <span id="aboutUs" />
        <BlockTitle>{TEXT.TITLE}</BlockTitle>
        <Row>
          <Col>
            <IconsBlock iconList={ICONS} mode={IconsMode.MuchText} />
          </Col>
          <AboutImage>
            <Image src={AboutUsImage} alt="about_us" />
          </AboutImage>
        </Row>
        <MiniForm btnText={TEXT.FORM_BTN} formText={TEXT.FORM} />
      </Block>
    );
  }
}
