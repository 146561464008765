import {
  faAngleDoubleUp, faCheckCircle,
  faPhone,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from 'react-input-mask';
import React, { useState } from 'react';

import { AnimateButton } from '../animate-button';
import { BigLabel } from '../big-label';
import { CheckboxButton } from '../checkbox-button';
import { SquareLable } from '../square-label';

import './CanKviz.scss';
import { ANSWERS, b, DISCOUNT, QUESTION } from './consts';
import ModalPolicy from '../modal/modal-privacy-policy/ModalPolicy';
import { bitrixLeadAPI } from '../../../../service/api/bitrixApi';
import { sendConversion } from '../../../../service/metrika/metrika';

interface Answers{
    option: string;
    type: string;
    name: string;
}

interface SelectedAnswers{
    object: Array<string>;
    objectOther: string;
    worker: Array<string>;
    workerOther: string;
    quantity: string;
    time: string;
    timeOther: '';
    phone: string;
}

interface OwnProps {
  sayThankYou: Function;
}

const CanKviz: React.FC<OwnProps> = (props: OwnProps) => {
    const [policyCheck, setPolicyCheck] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [input, setInput] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [answers, setAnswers] = useState<SelectedAnswers>({
        object: [],
        objectOther: '',
        worker: [],
        workerOther: '',
        quantity: '',
        time: '',
        timeOther: '',
        phone: '',
    });


    const handleChangeAnswers = (name: string, type: string, event: any ) => {
        if (type === 'object' || type === 'worker') {
          const answerArr = answers[type];
          if (answerArr.includes(name)){
            const num = answerArr.indexOf(name);
            answerArr.splice(num, 1);
          } else {
            answerArr.push(name);
          }
          setAnswers((state) => ({
            ...state,
            [type]: answerArr,
          }));
        } else if (type === 'time') {
          setAnswers((state) => ({
            ...state,
            [type]: name,
          }));
        }
    };

    const handleChangePage = (direct: string) => {
        let count = page ;
        if (direct === 'next' && page < QUESTION.length - 1){
            count += 1;
            setPage(count);
        } else if (direct === 'prev' && page > 0){
            count -= 1;
            setPage(count);
        }
    }

    const handleInputChange = (event: any, name: string) => {
        const {value} = event.target;
        setInput(value);
        setAnswers((state) => ({
          ...state,
          [name]: value,
    }))
    }

    const handleChangePolicy = () => {
      setPolicyCheck(!policyCheck);
    }

    const handleOpenModal = () => {
      setShowModal(!showModal);
    }

  const handleSubmit = (e: any) => {
      const {
        phone,
        object,
        objectOther,
        quantity,
        time,
        timeOther,
        worker,
        workerOther
      } = answers;

      const comment = `На объект: ${object.join(', ') || "<<Не указал>>" } ${objectOther}
       нужны рабочие: ${worker.join(', ') || "<<Не указал>>"} ${workerOther}
       в количестве: ${quantity || "<<Не указал>>"}
       на время: ${time || "<<Не указал>>"} ${timeOther}`;

      const name = 'CanKviz';
      const email = '';

    if (!policyCheck) {
      handleChangePolicy();
    } else if (answers.phone === '') {
      e.target.parentNode.parentNode.previousSibling.children[1].placeholder = 'Вы не ввели номер';
    } else {
      bitrixLeadAPI.create({phone, name, email, comments: comment}).then(() => {
        sendConversion();
        });
      props.sayThankYou(2);
    }
  }

    const renderPrompt = () => {
        if (page <= 1){
            return(
              <React.Fragment>
                  <SquareLable text="выберите один или несколько" type="active" />
              </React.Fragment>
              )
        } else return;
    }

    const lastPage = QUESTION.length - 1;
    const step = DISCOUNT / lastPage;

    if (page === lastPage) {
      return (
          <div className={b('container-transform')}>
            <div className={b('ty-box')}>
              <div>
                <div className={b('mainQuestion')}>{QUESTION[page]}</div>
                <div className={b('ty-text')}>
                  Заполните форму и получите скидку {DISCOUNT}% на первый заказ!
                </div>
              </div>
            </div>
            <div className={b('phone-box')}>
              <div>
              <div className={b('phone-tag')}>
                <div className={b('phone-tag-body')}>
                  Ваша скидка :
                </div>
                <div className={b('phone-tag-discount')}>
                  {DISCOUNT}%
                </div>
              </div>
              <div className={b('phone-form')}>
                <label htmlFor="phone">ВВЕДИТЕ ТЕЛЕФОН</label>
                <div className={b("phone-input")}>
                  <FontAwesomeIcon icon={faPhone}/>
                  <InputMask mask="+7 999 999 9999"
                             className={b('inputInPhone')}
                             onChange={(event) => handleInputChange(event, 'phone')}
                             id='phone'
                             placeholder='+7 999 999 9999'
                  />
                </div>
                <div className={b('phone-button')}>
                  <a href="#cankviz">
                  <AnimateButton
                    type='button'
                    onClick={(event) => handleSubmit(event)}
                    className={b('phoneBtn')}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: '10px'}}/>
                    Заказать рабочих
                  </AnimateButton>
                  </a>
                  <CheckboxButton
                    className={b('phone-policy')}
                    onChange={handleChangePolicy}
                    name='policy'
                    checked={policyCheck}
                  >
                    с
                    <button type="button"
                            onClick={() => handleOpenModal()}
                            className={b('emptyBtn')}>
                      политикой конфиденциальности
                    </button>
                    ознакомлен(а)
                  </CheckboxButton>
                  <ModalPolicy show={showModal} action={handleOpenModal}/>
                </div>
              </div>
              </div>
            </div>
          </div>
      )
    } else {
    return (
        <div className={b('container', {questions: true})}>

            <div className={b('questions')}>
                <div className={b('header')}>
                    <FontAwesomeIcon className={b('headerIcon')} icon={faTasks} />
                    Подберите подходящую бригаду ответив на 5 вопросов
                </div>
                <div className={b('questionContainer')}>
                    <div className={b('mainQuestion')}>{QUESTION[page]}</div>
                    <div className={b('tips')}>
                        {renderPrompt()}
                    </div>
                    <div className={b('answerContainer')}>
                        {ANSWERS[page].map((item: Answers, index: number) => {
                            let check = false;
                            if (item.name === 'object' || item.name === 'worker'){
                                if (answers[item.name].includes(item.option)){
                                    check = true;
                                }
                            } else if (item.name === 'objectOther' ||
                              item.name === 'workerOther' ||
                              item.name === 'timeOther') {
                              if (answers[item.name] === input && input !== '') {
                                check = true;
                              }
                            } else if (item.name === 'time') {
                              if (answers[item.name] === item.option) {
                                check = true;
                              }
                            }

                            if (item.type === 'checkbox' || item.type === 'radio'){
                                return (
                                  <CheckboxButton
                                    className={b('answerElement')}
                                    onChange={(event: any) => handleChangeAnswers( item.option, item.name, event)}
                                    name={item.option}
                                    checked={check}
                                    key={index}
                                    type={item.type}
                                  >
                                      {item.option}
                                  </CheckboxButton>
                                )
                            } else if (item.type === 'checkbox-input'){
                                return (
                                  <CheckboxButton
                                    className={b('answerElement')}
                                    onChange={(event: any) => handleChangeAnswers(item.option, item.name, event)}
                                    name={item.option}
                                    checked={check}
                                    key={index}
                                  >
                                      <input className={b('inputInBtn')}
                                             placeholder={item.option}
                                             onChange={(event) => handleInputChange(event, item.name)}/>
                                  </CheckboxButton>
                                )
                            }
                            else if (item.type === 'radio-input'){
                              return (
                                <CheckboxButton
                                  className={b('answerElement')}
                                  onChange={(event: any) => handleChangeAnswers(item.option, item.name, event)}
                                  name={item.option}
                                  checked={check}
                                  type='radio'
                                  key={index}
                                >
                                  <input className={b('inputInBtn')}
                                         placeholder={item.option}
                                         onChange={(event) => handleInputChange(event, item.name)}/>
                                </CheckboxButton>
                              )
                            } else if (item.type === 'big-input'){
                              return (
                                <div className={b('bigInput')}
                                  key={index}
                                >
                                  <input className={b('inputInBtn')}
                                         placeholder={item.option}
                                         onChange={(event) => handleInputChange(event, item.name)}/>
                                </div>
                              )
                            } else return '';
                        })}
                    </div>
                </div>
                <div className={b('footer')}>
                    <div className={b('progressBar')}>
                        <div className={b('progressBarLabel')}>
                            <span className={b('progressBarText')}>Готово:</span>
                            <span className={b('progressBarPercent')}>{25 * page}%</span>
                        </div>
                        <div className={b('progressBarField')}>
                            <span style={{width: `${25* page}%`}} className={b('progressBarLine')} />
                        </div>
                    </div>
                    <div className={b('footerControl')}>
                      <a href="#cankviz">
                        <AnimateButton
                          className={b('backButton')}
                          theme="secondary"
                          circle
                          onClick={() => handleChangePage('prev')}
                        >
                            {' '}
                          &#8592;{' '}
                        </AnimateButton>
                      </a>
                      <a href="#cankviz">
                        <AnimateButton onClick={() => handleChangePage('next')}> Далее &#8594; </AnimateButton>
                      </a>
                    </div>
                </div>
            </div>
            <div className={b('panel')}>
                <BigLabel>
                    <div className={b('discount')}>
                        <span>Ваша скидка:</span>
                        <span className={b('percent')}>{step * page} %</span>
                        <FontAwesomeIcon className={b('arrowIcon')} icon={faAngleDoubleUp} />
                    </div>
                </BigLabel>
            </div>
        </div>
    );
    }
};

export default React.memo(CanKviz);
