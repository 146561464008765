import styled from 'styled-components';
import {COLORS} from '../../constans/colors';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
`;

export const Element = styled.div`
  flex: 1;
  display: flex;
  margin: 10px 0;

  @media (max-width: 1030px) and (min-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 695px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ImgBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  padding-right: 10px;
  
  @media (max-width: 1030px) and (min-width: 768px) {
    padding: 0;
    width: auto;
  }

  @media (max-width: 695px) {
    padding: 0;
    width: auto;
  }
`;

export const Image = styled.img`
  max-width: 80px;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 10px;
  justify-content: center;
  line-height: 24px;

  @media (max-width: 1030px) and (min-width: 768px) {
    align-items: center;
    padding: 0;
    width: auto;
    font-size: 14px;
  }

  @media (max-width: 695px) {
    align-items: center;
    padding: 0;
    width: auto;
    font-size: 14px;
  }
`;

export const PrimaryText = styled.span`
  @media (max-width: 1030px) and (min-width: 768px) {
    text-align: center;
  }

  @media (max-width: 695px) {
    text-align: center;
  }
`;

export const SecondaryText = styled.span`
  color: ${COLORS.FONT_SECONDARY}
  line-height: 24px;
`;
