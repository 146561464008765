declare global {
  interface Window {
    ym?: any;
  }
}

export const sendConversion = () => {
  const button = document.getElementById('send');
  if (button) {
    if (window.ym) {
      button.dispatchEvent(new Event('click'));
    }
  }
};
