import React, {Component} from 'react';
import contacts from '../../../../../../service/api/contacts';
import Block from '../../../../components/block';
import ContactInfo from '../../../../components/contact-info/ContactInfo';
import ModalPolicy from '../../../../components/modal/modal-privacy-policy/ModalPolicy';
import TextWithSelection from '../../../../components/text-with-selection';
import {Colors} from '../../../../constans/colors';
import logo from '../../../../img/water.png';
import {FOOTER} from '../header-block/constants';
import {
  FooterLogoCol,
  HalfRow, HideText,
  LogoImage,
  Row,
  TextRightBlock,
  TextRow
} from './style';
import {OwnProps, S} from './types';

export default class FooterBlock extends Component<OwnProps, S> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  public handleOpenPrivacy = () => {
    this.setState(prevState => {
      return {
        isShow: !prevState.isShow,
      };
    });
  }

  public render() {
    return (
      <Block type={Colors.Dark} withoutPadding="footer">
        <Row>
          <HalfRow>
            <FooterLogoCol>
              <LogoImage src={logo} alt="logo" />
            </FooterLogoCol>
            <TextRightBlock onClick={this.handleOpenPrivacy}>
              <ModalPolicy show={this.state.isShow} action={this.handleOpenPrivacy} />
              <TextRow>
                <HideText>
                  <TextWithSelection text={FOOTER.RIGHT} />
                </HideText>
              </TextRow>
              <TextRow>
                <TextWithSelection text={FOOTER.RIGHT_SECOND} />
              </TextRow>
            </TextRightBlock>
          </HalfRow>
          <HalfRow>
            <ContactInfo selectItem={contacts.getContact()} hide={false} />
          </HalfRow>
        </Row>
      </Block>
    );
  }
}
