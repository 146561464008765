import React, {useMemo} from 'react';

import {cn} from '../../../../utils/classNames';
import './AnimateButton.scss';

type DefaultButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface OwnProps {
    type?: 'button' | 'submit';
    circle?: boolean;
    theme?: 'primary' | 'secondary';
}

const b = cn('AnimateButton');

const AnimateButton: React.FC<OwnProps & DefaultButtonProps> = ({
    type = 'button',
    circle = false,
    theme = 'primary',
    className = '',
    children,
    ...defaultProps
}) => {
    const buttonClass = useMemo(() => {
        return b('button', {circle, [theme]: true}, [className]);
    }, [circle, theme, className]);

    return (
        // eslint-disable-next-line react/button-has-type
        <button className={buttonClass} type={type} {...defaultProps}>{children}</button>
    );
};

export default React.memo(AnimateButton);
