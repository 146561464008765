import styled from 'styled-components';

import {Colors, COLORS} from '../../constans/colors';

interface StyledContainerProps {
  backgroundColor: Colors;
  backgroundImage: string;
  withoutPadding?: string;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  padding: ${props => props.withoutPadding !== 'footer' ? '30px 0' : '0'};

  background-color: ${props => props.backgroundColor || COLORS.BG_LIGHT};
  background-image: url(${props => props.backgroundImage || ''});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  
  position: relative;

  color: ${props => (props.backgroundColor === Colors.Dark ? COLORS.FONT_LIGHT : COLORS.FONT_PRIMARY)};

  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: 1700px) {
    height: ${props => (props.backgroundImage ? "80vh" : "auto")};
  }
  @media (max-width: 1200px) {
    background-position: -350px;
    padding: 0;
  }
  @media (max-width: 1100px) {
    background-position: -400px;
  }
  @media (max-width: 1000px) {
    background-position: -400px;
  }

  @media (max-width: 500px) {
    background-position: -400px 70px;
  }
  @media (max-width: 400px) {
    background-position: -600px 70px;
  }
`;

export const InnerContainer = styled.div`
  margin: 0 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  @media (min-width: 1200px) {
    margin: 0 10%;
  }
`;
