import styled from 'styled-components';

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1250px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
