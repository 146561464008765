import styled from 'styled-components';
import {COLORS} from '../../../constans/colors';

interface ModalShow {
  show: boolean;
}

export const PrivacyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 90%;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.BG_LIGHT};
  border-radius: 20px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const ModalTitle = styled.h1`
  color: ${COLORS.FONT_PRIMARY};
  font-size: x-large;
`;

export const PoliticaFrame = styled.iframe`
  overflow-y: scroll;
  width: 100%;
  margin: 20px;
  height: 70%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
`;

export const ModalBody = styled.div<ModalShow>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;
