import styled from 'styled-components';
import {COLORS} from '../../../../constans/colors';

export const QuestionBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 95%;
  height: 590px;
  background-color: ${COLORS.BG_LIGHT};
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  border: 1px solid ${COLORS.BORDER_GREY};
  border-radius: 5px;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: auto;
  }
`;

export const ArrowImg = styled.img`
  
`;

export const ArrowLink = styled.a`
  position: absolute;
  top: 50%;
  right: 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const QuestionForm = styled.form`
  margin: 20px;
  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    margin: 10px 40px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;

export const QuestionImg = styled.img`
  width: auto;
  height: auto;
  object-fit: cover;
  margin-right: 90px;
  border-radius: 5px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const QuestionTitle = styled.label`
  font-size: x-large;
  @media (max-width: 1200px) {
    margin-top: 10px;
    text-align: center;
  }
`;

export const QuestionFormInput = styled.input`
  border: none;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
  background: none;
  color: ${COLORS.FONT_PRIMARY};
  margin-bottom: 20px;
  height: 35px;
  padding: 0 10px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 805px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const QuestionSubtitle = styled.p`
  font-size: small;
  margin: 10px 0;
`;

export const ErrorText = styled.p`
  font-size: small;
  margin: 10px 0;
  color: red
`;

export const QuestionTextarea = styled.textarea`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid ${COLORS.BORDER_GREY};
  border-radius: 5px;

  @media (max-width: 805px) {
    width: 90%;
    margin: 20px 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    justify-content: space-around;
    width: 100%;
    flex-direction: row;
  }
  @media (max-width: 850px) {
    flex-direction: column;
    width: 100%;
  }
`;
