import {cn} from '../../../../utils/classNames';

export const b = cn('CanKviz');

export const QUESTION = [
  'На какой объект вам требуется персонал?',
  'Какой тип рабочих требуется на ваш объект?',
  'Сколько человек вам потребуется?',
  'На какой срок нужны рабочие',
  'Отлично, последний шаг!'
]

export const MAIN_SCREEN_TEXT = {
  title: '🔥 Скидка – 10% на первый заказ или дадим скидку на цену конкурента 😊',
  subtitle: 'Только для юр.лиц.',
  button: 'Подобрать персонал',
  bonus: 'Бонусы после прохождения теста'
}

export const THANK_YOU_TEXT = {
  title: 'Спасибо что прошли опросник! Эта информация очень важна для нас!',
  subtitle: 'Данные отправлены менеджеру, он свяжется с вами в ближайшее время',

}

export const DISCOUNT = 10;

export const ANSWERS = [
  [
    {
      option: 'Стройка',
      type: 'checkbox',
      name: 'object',
    },
    {
      option: 'Производство',
      type: 'checkbox',
      name: 'object',
    },
    {
      option: 'Склад',
      type: 'checkbox',
      name: 'object',
    },
    {
      option: 'Терминал',
      type: 'checkbox',
      name: 'object',
    },
    {
      option: 'Объект, где требуется рабочая сила',
      type: 'checkbox',
      name: 'object',
    },
    {
      option: 'Другое...',
      type: 'checkbox-input',
      name: 'objectOther',
    }
  ],
  [
    {
      option: 'Разнорабочие',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Грузчики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Землекопы',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Сварщики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Монтажники',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Бетонщики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Упаковщики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Стропальщики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Электрики',
      type: 'checkbox',
      name: 'worker',
    },
    {
      option: 'Другое...',
      type: 'checkbox-input',
      name: 'workerOther',
    },
  ],
  [
    {
      option: 'Напишите цифру, можно от и до',
      type: 'big-input',
      name: 'quantity',
    }
  ],
  [
    {
      option: '8 часов',
      type: 'radio',
      name: 'time',
    },
    {
      option: '2 - 5 дней',
      type: 'radio',
      name: 'time',
    },
    {
      option: '1 - 2 недели',
      type: 'radio',
      name: 'time',
    },
    {
      option: '2 - 4 недели',
      type: 'radio',
      name: 'time',
    },
    {
      option: 'Пока не решил',
      type: 'radio',
      name: 'time',
    },
    {
      option: 'Другое...',
      type: 'radio-input',
      name: 'timeOther',
    }
  ],
  [
    {
    option: 'Другое...',
    type: 'phone',
    name: 'phone',
  }
  ]
]
