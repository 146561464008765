import React, {Component} from 'react';

import Block from '../../../../components/block';
import TextWithSelection from '../../../../components/text-with-selection';
import {Colors} from '../../../../constans/colors';
import BgImage from '../../../../img/main_background.png';
import Scroll from '../../../../img/main_scroll.svg';
import {TEXT} from './constants';
import {Container, Img, Row, SubTitle, Title} from './style';
import {OwnProps} from './types';

export default class HeaderBlock extends Component<OwnProps> {
  public render() {
    return (
      <Block type={Colors.Dark} image={BgImage}>
        <span id="top" />
        <Container>
          <Title>
            <TextWithSelection text={TEXT.TITLE} />
          </Title>
          <SubTitle>
            <TextWithSelection text={TEXT.SUBTITLE} />
          </SubTitle>
        </Container>
        <Row className="justify-content-center">
          <a href="#advantages">
            <Img src={Scroll} alt="scroll down" />
          </a>
        </Row>
      </Block>
    );
  }
}
