import {faBars, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {map} from 'lodash';
import React, {useState} from 'react';

import logo from '../../img/main_logo.png';
import {
  MENU_LIST,
} from '../../pages/main-page/component/header-block/constants';
import TextWithSelection from '../text-with-icon';

import {cn} from '../../../../utils/classNames';
import './ContactInfo.scss';
import './TopPanel.scss';
import contacts from '../../../../service/api/contacts';

const b = cn('TopPanel');
const bc = cn('ContactInfo');

const TopPanel: React.FC = () => {


  const [hideMainMenu, setHideMainMenu] = useState(true);


  const mailLink = contacts.getContact().email;
  const number = `tel:${contacts.getContact().phone.label}`;


  const handleToggleMainMenu = () => {
    setHideMainMenu(!hideMainMenu);
  };

  return (
    <div className={b()}>
        <div className={b('logoCity')}>
        <div className={b('item', {marginAutoRight: true, center: true})}>
          <img className={b('logo')} src={logo} alt="silavnas"/>
        </div>
      </div>

      <div className={b('contacts')}>
          <React.Fragment>
            <div className={b('item')}>
                <a className={bc('link')} href={number}>
                   <span className={b('icon')}>
                  <FontAwesomeIcon icon={faPhone} />
                   </span>
                  <TextWithSelection text={contacts.getContact().phone} />
                </a>
            </div>
            <div className={b('item', {hide: true})}>
              <span className={b('icon')}>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <a className={b('link')} href={`mailto:${mailLink}`}>{mailLink}</a>
            </div>
          </React.Fragment>
      </div>

      <button
        type="button"
        className={b('mainMenuButton')}
        onClick={handleToggleMainMenu}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={(b('mainMenu', {hide: hideMainMenu}))}>
        <div className={b('linkContainer')}>
          {map(MENU_LIST, menuItem => (
            <a
              className={b('mainLink')}
              key={menuItem.text.label}
              href={menuItem.link}
              onClick={handleToggleMainMenu}
            >
              <TextWithSelection text={menuItem.text} />
            </a>
          ))}
        </div>
        <div className={b('phoneContainer')}>
          <a className={bc('link')} href={number}>
          <TextWithSelection text={contacts.getContact().phone}/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopPanel);
