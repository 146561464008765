import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {COLORS} from '../../constans/colors';



export const OkBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const OkContent = styled.div`
  display: flex;
  font-size: 50px;
  font-weight: bold;
  color: ${COLORS.FONT_PRIMARY};
  margin: 20px;
`;

export const OkInfo = styled.p`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: ${COLORS.FONT_ACTIVE};
`;

export const HomeLink = styled(Link)`
  text-decoration: none;
`;