import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  white-space: pre-wrap;
  font-size: 48px;
  line-height: 68px;
  text-shadow: 1px 1px 1px black;
  @media (max-width: 805px) {
    font-size: x-large;
    line-height: 30px;
  }
`;

export const SubTitle = styled.p`
  white-space: pre-wrap;
  font-size: 24px;
  line-height: 32px;
  text-shadow: 1px 1px 1px black;
  @media (max-width: 805px) {
    font-size: large;
    margin-top: 15px;
  }
  @media (max-width: 500px) {
    font-size: large;
    width: 70%;
    line-height: 20px;
    
  }
`;

export const Img = styled.img`
  margin-bottom: 50px;
  @media only screen and (min-width: 1700px) {
    position: absolute;
    bottom: 50px;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 150px 0px;
  @media screen and (max-width: 780px) {
    margin: 100px 0px;
  }
`;
