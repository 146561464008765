import React from 'react';
import { b, THANK_YOU_TEXT } from './consts';

const ThankYou : React.FC = () => {
  return (
    <div>
      <div className={b('thanks-container')}>
        <h4 className={b('title')}>{THANK_YOU_TEXT.title}</h4>
        <p className={b('subtitle')}>{THANK_YOU_TEXT.subtitle}</p>
      </div>
    </div>
  )
}
export default React.memo(ThankYou);
