import React, {Component} from 'react';
import Block from '../../../../components/block';
import Card from '../../../../components/info-card/Card';
import MiniForm from '../../../../components/mini_form/MiniForm';
import {Colors} from '../../../../constans/colors';
import Brigadir from '../../../../img/power-control/brigadir.png';
import Documentation from '../../../../img/power-control/documentation.png';
import Manager from '../../../../img/power-control/manager.png';
import {BlockSubtitle, BlockTitle} from '../../style';
import {TEXT} from './constants';
import {ContainerCard} from './style';

export default class PowerControlBlock extends Component {
  public render() {
    return (
      <Block type={Colors.Grey}>
        <span id="powerControl" />
        <BlockTitle>{TEXT.TITLE}</BlockTitle>
        <BlockSubtitle>{TEXT.SUBTITLE}</BlockSubtitle>
        <ContainerCard>
          <Card title={TEXT.CARD_TITLE.manager} text={TEXT.CARD_TEXT.manager} img={Manager} />
          <Card title={TEXT.CARD_TITLE.brigadir} text={TEXT.CARD_TEXT.brigadir} img={Brigadir} />
          <Card title={TEXT.CARD_TITLE.documentation} text={TEXT.CARD_TEXT.documentation} img={Documentation} />
        </ContainerCard>
        <MiniForm btnText={TEXT.FORM_BTN} formText={TEXT.FORM} />
      </Block>
    );
  }
}
