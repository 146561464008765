import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';

import {cn} from '../../../../utils/classNames';
import TextWithSelection from '../text-with-selection';
import {OwnProps} from './types';

import './ContactInfo.scss';

const b = cn('ContactInfo');

export default class ContactInfo extends Component<OwnProps> {
  public render() {
    const {selectItem, hide} = this.props;
    const number = `tel:${selectItem.phone.label}`;
    const mail = `mailto:op@ludi-sila.ru`;
    return (
      <div className={b('container')}>
        <div className={b('item')}>
          <div className={b('icon')}>
            <FontAwesomeIcon icon={faPhone} />
          </div>
            <a className={b('link')} href={number}>
              <TextWithSelection text={selectItem.phone} />
            </a>
        </div>
        <div className={b('item', {hide})}>
          <div className={b('icon')}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <a className={b('link')} href={mail}>op@ludi-sila.ru</a>
        </div>
      </div>
    );
  }
}
