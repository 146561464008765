import {withNaming} from '@bem-react/classname';

interface Mods {
  [name: string]: boolean;
}

const prefix = {e: '__', m: '-'};

const className = withNaming(prefix);

export const cn = (block: string) => (elem?: string, mods?: Mods, classList?: string[]) => {
    const mainClasses = className(block, elem)(mods);
    return `${mainClasses} ${(classList?.join(' ') ?? '')}`;
};
