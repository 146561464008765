import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Slide = styled.div`
  display: flex;
  position: relative;
  width: 80%;
  justify-content: center;
  align-items: center;
`;

export const SlideImg = styled.img`
  width: 100%;
  border-radius: 20px;
`;

export const LeftContent = styled.div`
  display: block;
  flex-direction: column;
  margin: 10px;
  width: 60%;
  align-items: center;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: 40%;
  max-height: 500px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 10px;
  @media (max-width: 769px) {
    width: 100%;
    max-height: none;
    margin: 20px;
  }
`;
