import React, { useState } from 'react';

import { b, MAIN_SCREEN_TEXT } from './consts';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AnimateButton from '../animate-button/AnimateButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CanKviz from './CanKviz';
import ThankYou from './ThankYou';


const FirstScreen: React.FC = () => {
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (page: number) => {
    setPage(page);
  }

  const renderContent = () => {
    if (page === 0) {
      return (
        <div className={b('container', {}, ['first-screen'])}>
          <h4 className={b('title')}>{MAIN_SCREEN_TEXT.title}</h4>
          <p className={b('subtitle')}>{MAIN_SCREEN_TEXT.subtitle}</p>
          <AnimateButton className={b('iconButton')} onClick={() => handleChangePage(1)}>
            <FontAwesomeIcon icon={faCheckCircle} />
            {MAIN_SCREEN_TEXT.button}
          </AnimateButton>
        </div>
      )
    } else if (page === 1) {
      return (
        <CanKviz sayThankYou={handleChangePage}/>
      )
    } else if (page === 2) {
      setTimeout(() => handleChangePage(0), 5000);
      return (
        <ThankYou />
      )
    } else return '';
  }

  return (
      <React.Fragment>
        {renderContent()}
      </React.Fragment>
  )



};

export default React.memo(FirstScreen);
