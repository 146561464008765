import {faLock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import {cn} from '../../../../utils/classNames';
import './BigLabel.scss';

type OwnProps = React.PropsWithChildren<{
    type?: 'pink' | 'yellow';
    disabled?: boolean;
}>;

const b = cn('BigLabel');

const BigLabel: React.FC<OwnProps> = ({type = 'yellow', disabled = false, children}) => {
    return (
        <div className={b('label', {[type]: true, disabled})}>
            {disabled && (
                <span className={b('disabledIcon')}>
                    <FontAwesomeIcon className={b('lock')} icon={faLock} />
                </span>
            )}
            {children}
        </div>
    );
};

export default React.memo(BigLabel);
