export enum IconsMode {
  Carousel = 'Carousel',
  Triple = 'Triple',
  MuchText = 'MuchText',
}

export interface IconObject {
  label: string;
  icon: string;
  subtitle?: string;
}

export type IconList = Array<IconObject>;

export interface OwnProps {
  iconList: IconList;
  mode: IconsMode;
}

export interface RenderImageFunction {
  (item: IconObject, index: number): JSX.Element;
}
