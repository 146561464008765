import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
`;

export const TextBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 24px;
  padding: 0 10px;
  @media (max-width: 820px){
    padding: 0;
  }
`;
