import React, { useState } from 'react';
import { SliderContainer, SliderImg} from './style';

interface SliderImage {
  img: string;
  name: string;
}

interface OwnProps {
  arr: Array<SliderImage>;
  effect: Effect
}

type Effect = 'fadeIn'|'leftIn'|'topIn';

const MySlider: React.FC<OwnProps> = ({ arr, effect }) => {

  const [slide, setSlide] = useState<number>(0);

  const changeSlide = (direction: string) => {
    clearTimeout(autoPlay);
    const len = arr.length - 1;
    if (direction === 'right' && slide < len) {
      setSlide(slide + 1);
    } else if (direction === 'right' && slide >= len) {
      setSlide(0);
    } else if (direction === 'left' && slide < 0) {
      setSlide(0);
    } else if (direction === 'right' && slide <= 0) {
      setSlide(len);
    }
  }

  const autoPlay = setTimeout(() => changeSlide('right'), 10000);

  return (
    <SliderContainer>
      {arr.map((item: SliderImage, index: number) => {
        let show = false;
        if (index === slide) {
          show = true;
        }
        return(
          <SliderImg
            src={item.img}
            alt={item.name}
            show={show}
            effect={effect}
            onClick={() => changeSlide('right')}
            key={index}
          />
        )
      })}
    </SliderContainer>
  )
}
export default React.memo(MySlider);