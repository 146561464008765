import React from 'react';
import Block from './Block';
import {OwnProps} from './types';

class SuspenseBlock extends React.Component<OwnProps> {
  public render() {
    return (
      <Block {...this.props} />
    );
  }
}

export default SuspenseBlock;
