import React from 'react';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {cn} from '../../../../utils/classNames';
import './SquareLable.scss';

const b = cn('SquareLabel');

interface OwnProps {
    text: string;
    type?: 'active' | 'inactive';
}

const SquareLable: React.FC<OwnProps> = ({text, type = 'inactive'}) => {
    const isActive = type === 'active';
    return (
        <span className={b('label', {active: isActive})}>
            {isActive && (
                <FontAwesomeIcon className={b('icon')} icon={faCheckCircle} />
            )}
            {text}
        </span>
    );
};

export default React.memo(SquareLable);
