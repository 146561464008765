export const TEXT = {
  TITLE: 'Сила в контроле',
  SUBTITLE:
    'Чтобы вы были довольны результатом, мы разработали трехступенчатую систему работы. \nОна помогает нам всегда быть в курсе происходящего и быстро решать поставленные задачи.',
  CARD_TITLE: {
    manager: 'Личный менеджер',
    brigadir: 'Бригадир',
    documentation: 'Отдел документооборота',
  },
  CARD_TEXT: {
    manager:
      'За ваш объект отвечает персональный менеджер. \n' +
      'Он собирает информацию для \n' +
      'запуска проекта, составляет расписание сотрудников \n' +
      'и корректирует работу над проектом. Менеджер всегда в курсе происходящего, поэтому ответить \n' +
      'на любой ваш вопрос.',
    brigadir:
      'За выполнение заданий и работу бригады отвечает бригадир. \n' +
      'Он руководит рабочими на объекте, ставит им задачи и распределяет \n' +
      'по местам.',
    documentation:
      'Наш отдел документооборота всегда  \n' +
      'на связи. Штатный юрист \n' +
      'и бухгалтер предоставят вам \n' +
      '7 вариантов договора и несколько способов оплаты на выбор. \n' +
      'Как показывает практика, \n' +
      'наши клиенты это ценят.',
  },
  FORM: {
    label: 'Заполните форму и мы решим вопросы с нехваткой персонала на вашем объекте',
    active: 'решим вопросы',
  },
  FORM_BTN: 'Решить вопрос',
};
