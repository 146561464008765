import PeopleIcon from '../../../../img/about-us-block/people.svg';
import PyramidIcon from '../../../../img/about-us-block/piramid.svg';
import RocketIcon from '../../../../img/about-us-block/rocket.svg';
import TwentyFourIcon from '../../../../img/about-us-block/twenty_four.svg';

export const TEXT = {
  TITLE: 'О нас',
  FORM: {
    label: 'Заполните форму и мы соберем вам бригаду\n рабочих от 1 до 100 человек',
    active: 'соберем вам бригаду',
  },
  FORM_BTN: 'Собрать бригаду',
};

export const ICONS = [
  {
    label:
      'Организация Сильные люди предоставляет разнорабочих и квалифицированный персонал на строительные и производственные площадки с 2014 года.',
    icon: RocketIcon,
  },
  {
    label:
      'На февраль 2022 года в нашей базе оцифрованы и хранятся данные 25000 готовых к работе людей. Среднемесячный рост базы в 2022 году составляет 12%.',
    icon: PeopleIcon,
  },
  {
    label:
      'Мы готовы предоставить как оперативные группы рабочих до 10 человек в срок 24 часа, так и бригады до 100 человек сформированные за период от 7 до 10 дней.',
    icon: TwentyFourIcon,
  },
  {
    label: '3 столпа нашей компании - это технологичность, человеческое отношение и строгое соблюдение сроков.',
    icon: PyramidIcon,
  },
];
