import styled from 'styled-components';
import {COLORS} from '../../constans/colors';
import {clearButton} from '../../pages/main-page/style';

interface ImageColProps {
  image: string;
}

interface MenuButtonProps {
  isActive: boolean;
}

export const Row = styled.div`
  display: flex;
  flex: 1;
  height: 100%;

  @media (max-width: 530px) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled.div`
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  min-height: 560px;

  margin-top: 10px;
  display: flex;
`;

export const ContentCol = styled.div`
  width: 70%;

  display: flex;
  flex-direction: column;

  padding: 35px;

  @media (max-width: 769px) {
    width: 100%;
    padding: 35px 5px;
  }
`;

export const ImageCol = styled.div<ImageColProps>`
  width: 30%;

  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  border-radius: 0 14px 14px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TopMenu = styled.div`
  margin-bottom: 50px;
  display: flex;
  height: 55px;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  height: 100%;
  flex: 1;
`;

export const MenuButton = styled.button<MenuButtonProps>`
  ${clearButton};

  display: flex;
  justify-content: center;
  position: relative;
  color: ${props => (props.isActive ? COLORS.FONT_ACTIVE : COLORS.FONT_SECONDARY)};
  height: 100%;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${props => (props.isActive ? COLORS.BG_ACTIVE : COLORS.BG_SECONDARY)};
    top: 100%;
    left: 0;
  }
`;

export const ArrowButton = styled.button`
  ${clearButton};

  margin: 0 10px;
`;
