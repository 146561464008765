import React from 'react';
import {v4 as uuidv4} from 'uuid';
import Block from '../../../../components/block/Block';
import {Colors} from '../../../../constans/colors';
import {
  AdvantagesContent,
  AdvantagesItems,
  AdvantagesIcon,
  AdvantagesTitle, AdvantagesText,
} from './style';
import {ADVANTAGES, ADVANTAGES_TITLE} from './constants';
import TextWithSelection from '../../../../components/text-with-selection';
import {Advantages} from './types';


export default class AdvantagesBlock extends React.Component {


  public render() {
    return (
      <Block type={Colors.Light}>
        <span id="advantages" />
        <AdvantagesContent>
          <AdvantagesTitle>
            <TextWithSelection text={ADVANTAGES_TITLE}/>
          </AdvantagesTitle>
          {ADVANTAGES.map((item:Advantages) => {
            return (
              <AdvantagesItems key={uuidv4()}>
                <div>
                  <AdvantagesIcon src={item.icon} alt="image"/>
                </div>
                <AdvantagesText>
                  {item.text}
                </AdvantagesText>
            </AdvantagesItems>
            )
          })}
        </AdvantagesContent>
      </Block>
    )
  }

}
