import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 450px) {
    flex-direction: row;
  }
`;

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  flex: 1;
  flex-basis: 145px;
`;

export const ImageBlock = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PrimaryText = styled.span`
  text-align: center;
`;

export const SecondaryText = styled.span`
  text-align: center;
`;
