import React, {Component} from 'react';

import * as MuchTextStyle from './much-text-style';
import * as CarouselStyle from './style';
import * as TripleStyle from './triple-style';
import {IconObject, IconsMode, OwnProps, RenderImageFunction} from './types';

export default class IconsBlock extends Component<OwnProps> {
  private renderImageCarousel = (item: IconObject, index: number) => {
    return (
      <CarouselStyle.Element key={`${item.label}_${index}`}>
        <CarouselStyle.ImgBlock>
          <CarouselStyle.Image src={item.icon} />
        </CarouselStyle.ImgBlock>
        <CarouselStyle.TextBlock>
          <CarouselStyle.PrimaryText>{item.label}</CarouselStyle.PrimaryText>
        </CarouselStyle.TextBlock>
      </CarouselStyle.Element>
    );
  }

  private renderImageTriple = (item: IconObject, index: number) => {
    return (
      <TripleStyle.Element key={`${item.label}_${index}`}>
        <TripleStyle.ImageBlock>
          <CarouselStyle.Image src={item.icon} />
        </TripleStyle.ImageBlock>
        <TripleStyle.TextBlock>
          <TripleStyle.PrimaryText>{item.label}</TripleStyle.PrimaryText>
          {item.subtitle && <TripleStyle.SecondaryText>{item.subtitle}</TripleStyle.SecondaryText>}
        </TripleStyle.TextBlock>
      </TripleStyle.Element>
    );
  }

  private renderImageMuchText = () => {
    return (
      <MuchTextStyle.Container>
        {this.props.iconList.map((item: IconObject, index: number) => {
          return (
            <MuchTextStyle.Row key={`${item.label}_${index}`}>
              <MuchTextStyle.ImageBlock>
                <CarouselStyle.Image src={item.icon} />
              </MuchTextStyle.ImageBlock>
              <MuchTextStyle.TextBlock>{item.label}</MuchTextStyle.TextBlock>
            </MuchTextStyle.Row>
          );
        })}
      </MuchTextStyle.Container>
    );
  }

  private renderImageRows = (
    ContainerComponent: React.ComponentType,
    RowComponent: React.ComponentType,
    renderFunc: RenderImageFunction
  ) => {
    const rows: JSX.Element[] = [];
    let row: JSX.Element[] = [];

    this.props.iconList.forEach((item: IconObject, index: number) => {
      const element = renderFunc(item, index);

      row.push(element);

      if (row.length === 2) {
        rows.push(<RowComponent key={`${index}_row`}>{row}</RowComponent>);
        row = [];
      }
    });

    if (row.length) {
      rows.push(<RowComponent>{row}</RowComponent>);
    }

    return <ContainerComponent>{rows}</ContainerComponent>;
  }

  public render() {
    const {mode} = this.props;

    switch (mode) {
      case IconsMode.Carousel: {
        return this.renderImageRows(CarouselStyle.Container, CarouselStyle.Row, this.renderImageCarousel);
      }
      case IconsMode.Triple: {
        return this.renderImageRows(TripleStyle.Container, TripleStyle.Row, this.renderImageTriple);
      }
      case IconsMode.MuchText: {
        return this.renderImageMuchText();
      }
      default: {
        return null;
      }
    }
  }
}
