import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';

import {cn} from '../../../../utils/classNames';
import Block from '../../components/block/Block';
import {Colors} from '../../constans/colors';
import {LABELS} from './consts';

import './NotFound.scss';

const b = cn('NotFound');

const NotFound: React.FC = () => (
  <React.Fragment>
    <Block type={Colors.Light}>
      <div className={b('container')}>
        <h1 className={b('title')}>{LABELS.TITLE}</h1>
        <p className={b('subTitle')}>{LABELS.SUBTITLE}</p>
        <Link className={b('linkButton')} to="/">
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className={b('span')}>{LABELS.BACK_LINK}</span>
        </Link>
      </div>
    </Block>
    <Block type={Colors.Dark}>
      <div className={b('row')} />
    </Block>
  </React.Fragment>
);

export default NotFound;
