import styled from 'styled-components';
import { COLORS } from '../../../../constans/colors';
import Vector from '../../../../img/Vector.png'

export const AdvantagesContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.FONT_LIGHT};
  border-radius: 10px;
  background-color: ${COLORS.BG_BLUE};
  background-image: url(${Vector});
  padding: 30px 70px;
  margin: 60px 0;
  @media (max-width: 980px) {
    padding: 10px;
  }
  @media (max-width: 620px) {
    padding: 0;
  }
`;

export const AdvantagesItems = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px;
  @media (max-width: 620px) {
    flex-wrap: wrap;
  }
`;

export const AdvantagesText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 20px;
  font-size: 18px;
  align-items: center;
  @media (min-width: 1700px) {
    font-size: 24px;
  }
  @media (max-width: 620px) {
    margin: 0 10px;
  }
`;

export const AdvantagesIcon = styled.img`
  color: ${COLORS.FONT_LIGHT};
  margin: 10px 20px;
  @media (min-width: 1700px) {
    width: 100px;
  }
`;

export const AdvantagesTitle = styled.h2`
  font-size: 36px;
  margin: 10px;
  @media (min-width: 1700px) {
    font-size: 42px;
  }
  @media (max-width: 620px) {
    font-size: 28px;
  }
`;